.app {
  max-width: 500px;
  margin-top: 100px;
  margin: 20px auto;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  transition: 0.3 ease;
  @media (max-width: 500px) {
    margin-top: 0;
  }
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

/* Styling for the container div */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
}

/* Styling for the SVG logo */
.logo {
  width: 40%; /* Take up 33% of the app width */
  max-width: 200px; /* Set a maximum width if necessary */
  height: auto; /* Maintain aspect ratio */
}

.form-group {
  margin-bottom: 20px;
}

.row-group {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

label {
  display: block;
  font-weight: bold;
  /* margin-left: -5px; */
  padding-bottom: 5px;
}

.heading {
  font-size: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 10px;
  font-weight: 400;
}

@keyframes spin {
  0% {
    background-color: rgb(255, 255, 255);
  }
  50% {
    background-color: rgb(211, 222, 215);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}

.spinner {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 0;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-sizing: border-box;
  animation: spin 2s linear infinite;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 0;
  background-color: rgb(226, 235, 229);
  border-radius: 4px;
  box-sizing: border-box;
}
:disabled {
  background-color: rgb(230, 230, 230);
}

textarea {
  resize: vertical;
}

.error-message {
  /* padding-left: 10px; */
  padding-top: 5px;
  color: rgb(222, 72, 72);
  font-size: 12px;
  font-weight: 600;
}

.submit-button {
  background-color: #0a4f00;
  color: #ffffff;
  padding: 10px 50px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;
  font-weight: bold;
}
.submit-button:disabled,
.submit-button:disabled:hover,
.submit-button:disabled:active,
.submit-button:disabled:hover:active {
  color: #ffffff;
  background-color: gray;
}

.submit-button:hover {
  background-color: #efdb21;
  color: #000;
  margin: auto;
}

.google-button {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.google-button:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}

.google-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.button-text {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.signout-button {
  margin-top: 10px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffd5d2; /* Red color for sign out */
  border: none;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.signout-button:hover {
  background-color: #d44026; /* Darker red color on hover */
  color: #ccc;
}

.separator {
  height: 2px;
  width: 100%;
  max-width: calc(100% - 40px); /* Adjust based on the horizontal padding */
  margin: 10px auto; /* 10px vertical margin */
  background-color: #ccc; /* Color of the separator */
}
